import React, { useImperativeHandle, useState, useEffect, useCallback, forwardRef, memo } from 'react';

import classy from '@core/utils/classy';

import './style.scss';

interface TextareaProps extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'value'> {
  /* Expand the text area vertically to fit its content */
  autoGrow?: boolean;
  className?: string;
  isMinimal?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  resize?: 'both' | 'horizontal' | 'none' | 'vertical';
  size?: 'lg' | 'md' | 'sm';
  value?: React.TextareaHTMLAttributes<HTMLTextAreaElement>['value'] | null;
}

const Textarea = memo(
  forwardRef<HTMLTextAreaElement | undefined, TextareaProps>(
    (
      {
        autoGrow,
        className,
        isMinimal = false,
        onChange,
        resize = 'vertical',
        size = 'md',
        ...attributes
      }: TextareaProps,
      forwardedRef,
    ) => {
      const [value, setValue] = useState(attributes.value);
      const [textareaEl, setTextareaEl] = useState<HTMLTextAreaElement | null>(null);

      useImperativeHandle(forwardedRef, () => textareaEl || undefined);

      useEffect(() => {
        setValue(attributes.value);
      }, [attributes.value]);

      const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
        event => {
          setValue(event.target.value);
          onChange?.(event);
          if (autoGrow && textareaEl) {
            textareaEl.style.height = 'auto';
            textareaEl.style.height = `${textareaEl.scrollHeight}px`;
          }
        },
        [onChange, autoGrow, textareaEl],
      );

      useEffect(() => {
        if (!autoGrow || !textareaEl) return;

        textareaEl.style.height = `${textareaEl.scrollHeight}px`;
        textareaEl.style.overflowY = 'hidden';
      }, [autoGrow, textareaEl]);

      return (
        <textarea
          {...attributes}
          ref={setTextareaEl}
          className={classy(
            'Textarea',
            `Textarea_${size}`,
            `Textarea_${resize}`,
            isMinimal && 'Textarea_minimal',
            className,
          )}
          onChange={handleChange}
          value={value || ''}
        />
      );
    },
  ),
);

export default Textarea;
